export const fieldList = [
  {
    key: 'submission_date',
    label: 'Tanggal Pengajuan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',

  },
  {
    key: 'submission_type',
    label: 'Kategori Pengajuan',
    thClass: 'text-black text-capitalize',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',

  },
  {
    key: 'warehouse_logo_url',
    label: 'Mitra Gudang',
    thClass: 'text-black text-capitalize',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',

  },
  {
    key: 'partner_logo_url',
    label: 'Partner',
    thClass: 'text-black text-capitalize',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',

  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'text-black text-capitalize',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'text-center p-1',

  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-black text-capitalize',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',

  },
]

export const statusOptions = [
  {
    value: null,
    text: 'Pilih Status',
  },
  {
    value: 0,
    text: 'Sedang Diajukan',
  },
  {
    value: 1,
    text: 'Disetujui',
  },
  {
    value: 2,
    text: 'Ditolak',
  },
]

export const categoryOptions = [
  {
    value: null,
    text: 'Pilih Kategori',
  },
  {
    value: 1,
    text: 'Berlangganan',
  },
  {
    value: 2,
    text: 'Tambah Produk',
  },
]

export const listDetail = [
  {
    key: 'checkbox',
    label: '',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'product_height',
    label: 'Volume',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'range_price',
    label: 'Harga Barang',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'packing_material',
    label: 'Jenis Bahan Packing',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1 text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'packing_layer',
    label: 'Lapisan Packing',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1 text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'fulfillment_cost',
    label: 'Biaya Fulfillment',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1 text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'addon_cost',
    label: 'Biaya Tambahan',
    thClass: 'text-black text-capitalize',
    class: 'bg-white p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const packingMaterialOptions = [
  {
    value: 1,
    text: 'Plastik',
  },
  {
    value: 2,
    text: 'Bubble Wrap',
  },
  {
    value: 3,
    text: 'Kardus',
  },
]

export const fieldsBundling = [
  {
    key: 'no',
    label: 'No',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
    tdClass: 'text-black',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
    tdClass: 'text-black',
  },
  {
    key: 'qty',
    label: 'Jumlah',
    thClass:
'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
    tdClass: 'text-black text-center',
  },
]
